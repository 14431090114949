html {
  width: 100%;
  height: 100%;
}

body {
  color: #004057 !important;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.MuiBackdrop-root {
  background: linear-gradient(
    100deg,
    rgba(0, 64, 87, 0.7) 0%,
    rgba(0, 64, 87, 1) 100%
  );
}

.MuiFilledInput-root {
  input {
    height: unset;
    padding: 25px 12px 8px 12px;
  }
}

.mui-field .MuiInputBase-root {
  border-bottom: 2px solid #004057;
}

.mui-field {
  .slider-switch {
    margin-bottom: 10px;
  }

  :focus-visible {
    outline: unset;
  }

  .search-field {
    input {
      padding-left: 0;
    }
  }
}

.MuiAlert-standardError {
  color: #d32f2f;
  background-color: #fdeded;
  .MuiAlert-icon {
    color: #d32f2f;
  }
}

.MuiAlert-standardInfo {
  color: #0288d1;
  background-color: #e5f6fd;
  .MuiAlert-icon {
    color: #0288d1;
  }
}

.MuiLoadingButton-loading {
  color: #616161 !important;

  .MuiLoadingButton-loadingIndicator {
    color: #1f497d !important;
  }
}

.flex {
  display: flex;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.align-end {
  align-items: flex-end;
}

.flex-grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.row-wrap {
  flex-wrap: wrap;
}

.otpInputStyle {
  width: 40px !important;
  height: 40px;
  margin: 0 8px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  border: none;
  border-bottom: 2px solid #004057;
  &:focus-visible {
    outline: unset;
  }
}

.MuiFormLabel-root {
  color: #004057;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.pointer {
  cursor: pointer;
}

.offline-row {
  pointer-events: none;
  opacity: 0.5;
}

.ellipsize-left {
  /* Standard CSS ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;

  /* Beginning of string */
  direction: rtl;
  text-align: left;
}

.text-link {
  color: inherit;
}

.rotateIcon {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pipe {
  height: 16px;
  margin: 0 3px;
  border-right: 1px solid #004057;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.capitalize {
  text-transform: capitalize;
}

.button-link {
  padding: 0;
  min-width: 0;
  padding: 0 2px;
}

.bar {
  height: 16px;
  margin: 0 3px;
  border-right: 1px solid #004057;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gm-style-iw-ch {
  padding-top: 0 !important;
}

.gm-ui-hover-effect {
  height: 32px !important;
}
